(function ($) {
  Drupal.behaviors.benefitsBlockFormatter = {
    attach: function (context) {
      var $template = $('.js-benefits-block-formatter-v1', context);

      $template.each(function () {
        var thisFormatter = $(this);
        var $benefitsContent = $('.js-benefits-block-formatter-carousel', thisFormatter);
        var arrowsDiv = thisFormatter.find('.carousel-controls');
        var dotsDiv = thisFormatter.find('.carousel-dots');
        var basicSlide = '.js-benefits-block-formatter-slide';
        var settings = {
          speed: 1000,
          appendArrows: arrowsDiv,
          appendDots: dotsDiv,
          arrows: true,
          dots: false,
          slide: basicSlide,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          variableWidth: true,
          responsive: [
            {
              breakpoint: 1023,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                focusOnSelect: true
              }
            }
          ]
        };

        // Init this carousel with our settings
        $benefitsContent.slick(settings);
      });
    }
  };
})(jQuery);
